<template>
    <Load :loading="loading" v-if="loading"/>
    <region-wrapper v-else-if="orderList.length > 0">
        <item-header :title="t('myOrders.myOrders')"/>
        <a-spin :spinning="moreOrderLoading">
            <template #indicator>
                <Loading/>
            </template>
        <my-orders-item v-for="order in orderList" :key="order.orderNumber" :order="order" />
        </a-spin>
        <button v-if="orderTotal > 10 && orderList.length < orderTotal" class="view-more" @click="moreOrder">{{t('myOrders.vMore')}}</button>
    </region-wrapper>
    <not-order v-else/>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import MyOrdersItem from '@/components/order/MyOrdersItem.vue';
import Cookies from 'js-cookie';
import { getOrder } from '@/services/user';
import type { Order } from '@/services/interface/response';
import { Spin } from 'ant-design-vue';
import Loading from '@/components/Alert/Loading.vue';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';
import NotOrder from './NotOrder.vue';

export default defineComponent({
  name: 'MyOrders',
  components: {
    ItemHeader,
    RegionWrapper,
    MyOrdersItem,
    NotOrder,
    Loading,
    Load,
    ...importComponents(
      Spin,
    ),
  },
  setup() {
    const { t } = useI18n();

    // 首次进入页面的loading效果
    const loading = ref(true);
    // 加载更多订单的loading效果
    const moreOrderLoading = ref(false);
    const orderList: Order[] = reactive([]);
    // 当前页数
    const current = ref(1);
    // 每页几条
    const pageSize = ref(10);
    // 订单总数
    const orderTotal = ref(0);
    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        try {
          const res = await getOrder({ current: current.value, pageSize: pageSize.value });
          if (res.success) {
            Object.assign(orderList, res.data.list);
            orderTotal.value = res.data.total;
            loading.value = false;
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
    const moreOrder = async () => {
      moreOrderLoading.value = true;
      current.value += 1;
      try {
        const res = await getOrder({ current: current.value, pageSize: pageSize.value });
        if (res.success) {
          Object.assign(orderList, [...orderList, ...res.data.list]);
          moreOrderLoading.value = false;
        }
      } catch (e) {
        console.log(e);
      }
    };

    return {
      orderList,
      moreOrder,
      loading,
      orderTotal,
      moreOrderLoading,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    :deep(){
        main{
            padding: 0 83px 22px;
        }
    }
    :deep(header){
        h2{
            text-align: left;
            margin-bottom: 36px;
        }
    }
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #f4f4f4;
  z-index: 10;
  border-bottom: 3px solid #e1e1e1;
}
  :deep(.ant-spin){
      max-height: none !important;
      .ant-spin-dot{
          position: sticky!important;
      }
  }
.view-more{
    border: none;
    outline: none;
    width: 175px;
    height: 44px;
    background: #F5F5F5;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color:#545454;
    line-height: 17px;
    cursor: pointer;
    transition: all 200ms;
    &:hover{
        color: #fff;
        background: $theme-color;;
    }
}
</style>
