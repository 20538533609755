
import importComponents from '@/utils/import-components';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { Button } from 'ant-design-vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'MyOrdersItem',
  components: {
    ...importComponents(
      Button,
    ),
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const orderStatus = {
    //   1: 'prepared',
    //   2: 'delivered',
    //   3: 'finished',
      0: t('myOrders.closed'), // 已关闭
      1: t('myOrders.prepared'), // 待发货
      2: t('myOrders.delivered'), // 已发货
      3: t('myOrders.completed'), // 已完成
      4: t('myOrders.obligation'), // 其他待付款
      5: t('myOrders.obligation'), // 转账的待付款
    };
    const orderData = computed(() => props.order.orderOn.split(' '));
    const router = useRouter();
    const toOrderDetail = () => {
      router.push(`/user/orders-details?${props.order.orderNumber}`);
    };

    return {
      orderStatus,
      orderData,
      t,
      toOrderDetail,
    };
  },
});
