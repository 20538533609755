
import importComponents from '@/utils/import-components';
import {
  defineComponent, onBeforeMount, reactive, ref,
} from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import MyOrdersItem from '@/components/order/MyOrdersItem.vue';
import Cookies from 'js-cookie';
import { getOrder } from '@/services/user';
import type { Order } from '@/services/interface/response';
import { Spin } from 'ant-design-vue';
import Loading from '@/components/Alert/Loading.vue';
import Load from '@/components/common/Load.vue';
import { useI18n } from 'vue-i18n';
import NotOrder from './NotOrder.vue';

export default defineComponent({
  name: 'MyOrders',
  components: {
    ItemHeader,
    RegionWrapper,
    MyOrdersItem,
    NotOrder,
    Loading,
    Load,
    ...importComponents(
      Spin,
    ),
  },
  setup() {
    const { t } = useI18n();

    // 首次进入页面的loading效果
    const loading = ref(true);
    // 加载更多订单的loading效果
    const moreOrderLoading = ref(false);
    const orderList: Order[] = reactive([]);
    // 当前页数
    const current = ref(1);
    // 每页几条
    const pageSize = ref(10);
    // 订单总数
    const orderTotal = ref(0);
    onBeforeMount(async () => {
      if (Cookies.get('token')) {
        try {
          const res = await getOrder({ current: current.value, pageSize: pageSize.value });
          if (res.success) {
            Object.assign(orderList, res.data.list);
            orderTotal.value = res.data.total;
            loading.value = false;
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
    const moreOrder = async () => {
      moreOrderLoading.value = true;
      current.value += 1;
      try {
        const res = await getOrder({ current: current.value, pageSize: pageSize.value });
        if (res.success) {
          Object.assign(orderList, [...orderList, ...res.data.list]);
          moreOrderLoading.value = false;
        }
      } catch (e) {
        console.log(e);
      }
    };

    return {
      orderList,
      moreOrder,
      loading,
      orderTotal,
      moreOrderLoading,
      t,
    };
  },
});
