<template>
<region-wrapper class="no-order">
    <img src="@/assets/icons/notorder.png" alt="no order">
    <p class="no">{{t('notOrder.p1')}}</p>
    <p class="can">{{t('notOrder.p2')}}</p>
</region-wrapper>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { defineComponent } from 'vue';
import RegionWrapper from '@/components/common/RegionWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NotOrder',
  components: {
    RegionWrapper,
    ...importComponents(
    ),
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    .no-order{
        img{
            width: 47px;
            height: 54px;
            margin-top: 246px;
            margin-bottom: 23px;
        }
        p{
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            &.no{
                color: $font-color;
            }
            &.can{
                margin-bottom: 261px;
                color: #A9A9A9;
            }
        }
    }
</style>
