<template>
    <div class="my-orders-item">
        <div class="order-item-title">
            <div>
                <span class="title">{{t('myOrdersItem.p1')}}</span>
                <span>{{order.orderNumber}}</span>
            </div>
            <div :class="`${order.orderStatus === '5' && 'pay-order-status'} order-status`">{{orderStatus[order.orderStatus]}}</div>
        </div>
        <div class="order-item-goods">
            <div class="goods-img">
                <router-link :to="`/user/orders-details?${order.orderNumber}`">
                    <img :src="`${order.goodsPic}?x-oss-process=image/resize,w_100,h_100`" alt="order item img">
                    <span class="goods-total">{{order.goodsCount}} {{order.goodsCount>1?'items':'item'}}</span>
                </router-link>
            </div>
            <div class="goods-message">
                <div class="main-message">{{t('myOrdersItem.p3')}} #{{order.orderNumber}} {{t('myOrdersItem.p4')}} {{orderStatus[order.orderStatus]}}</div>
                <div class="detail-message">{{orderData[0]}} {{orderData[1]}}{{orderData[2]==='ago'? '': ','}} {{orderData[2]}}</div>
                <div class="goods-money">{{t('global.currency')}}{{order.sellPrice}}</div>
            </div>
            <a-button class="to-details" @click="toOrderDetail">{{t('myOrdersItem.p2')}}</a-button>
        </div>
    </div>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { Button } from 'ant-design-vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'MyOrdersItem',
  components: {
    ...importComponents(
      Button,
    ),
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const orderStatus = {
    //   1: 'prepared',
    //   2: 'delivered',
    //   3: 'finished',
      0: t('myOrders.closed'), // 已关闭
      1: t('myOrders.prepared'), // 待发货
      2: t('myOrders.delivered'), // 已发货
      3: t('myOrders.completed'), // 已完成
      4: t('myOrders.obligation'), // 其他待付款
      5: t('myOrders.obligation'), // 转账的待付款
    };
    const orderData = computed(() => props.order.orderOn.split(' '));
    const router = useRouter();
    const toOrderDetail = () => {
      router.push(`/user/orders-details?${props.order.orderNumber}`);
    };

    return {
      orderStatus,
      orderData,
      t,
      toOrderDetail,
    };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';
    .my-orders-item{
        width: 1115px;
        // height: 217px;
        background: #F5F5F5;
        border-radius: 4px;
        margin-bottom: 36px;
        .order-item-title{
            padding: 21px 25px 0 26px;
            display: flex;
            align-items: center;
            padding-bottom: 22px;
            border-bottom: 2px solid #EDEDED;
            div{
                span{
                    font-size: 14px;
                    font-weight: 600;
                    color: $font-color-weak;
                    line-height: 17px;
                    &.title{
                        font-weight: 400;
                        color: #969696;
                        margin-right: 16px;
                    }
                }
                &.order-status{
                    font-size: 14px;
                    font-weight: 600;
                    color: #3962AD;
                    line-height: 17px;
                    &.pay-order-status{
                        color: #EA4F52;
                    }
                }
                &:last-child{
                   margin-left: auto;
                }
            }
        }
        .order-item-goods{
            padding: 18px 25px;
            display: flex;
            align-items: flex-end;
            .goods-img{
                margin-right: 23px;
                font-size: 0;
                a {
                    width: 100px;
                    display: inline-block;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    background: #FFF;
                    img{
                        margin: 10px 6px 6px;
                    }
                    .goods-total{
                        display: inline-block;
                        width: 100%;
                        height: 19px;
                        background: $theme-color;
                        font-size: 12px;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 19px;
                        text-align: center;
                        user-select: none;
                    }
                }
            }
            .goods-message{
                .main-message{
                    font-size: 14px;
                    font-weight: 600;
                    color: $font-color-weak;
                    line-height: 17px;
                    margin-bottom: 15px;
                }
                .detail-message{
                    font-size: 12px;
                    font-weight: 400;
                    color: $font-color-weak;
                    line-height: 15px;
                    margin-bottom: 24px;
                }
                .goods-money{
                    font-size: 18px;
                    font-family: Inter-ExtraBold, Inter;
                    font-weight: 800;
                    color: #484848;
                    margin-bottom: 10px
                }
            }
            .to-details{
                height: 35px;
                border-radius: 8px;
                border: 1px solid #EA4F52;
                font-size: 15px;
                font-family: Inter-SemiBold, Inter;
                font-weight: 600;
                color: #EA4F52;
                margin-left: auto;
                background: inherit
            }
        }
    }
</style>
